@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
:root {
  --font-sans: "Inter", sans-serif;

  /* BLACK */
  --msys-ds-black-primitive: #323232;

  /* GRAY */
  --msys-ds-gray-description: #606774;
  --msys-ds-gray-disabled: #949494;
  --msys-ds-gray-placeholder: #AFAFAF;

  /* OUTLINE */
  --msys-ds-outline-dark: #F3F3F3;
  --msys-ds-outline-default: #E4E4E4;
  --msys-ds-outline-disabled: #EDEDED;
  --msys-ds-outline-light: #FAFAFA;

  /* PRIMARY */
  --msys-ds-primary-base: #836231;
  --msys-ds-primary-hover: #1d0f00;
  --msys-ds-primary-muted: #fffaf4;

  /* SECONDARY */
  --msys-ds-secondary-base: #263B69;
  --msys-ds-secondary-hover: #2B4478;
  --msys-ds-secondary-muted: #EEF3FF;

  /* INFO */
  --msys-ds-info-base: #469CE5;
  --msys-ds-info-hover: #1D75C0;
  --msys-ds-info-muted: #F2F5FF;

  /* SUCCESS */
  --msys-ds-success-base: #63D675;
  --msys-ds-success-hover: #33BB48;
  --msys-ds-success-muted: #EAFFED;
  --msys-ds-success-head: #31770E;

  /* DANGER */
  --msys-ds-danger-base: #DB4343;
  --msys-ds-danger-hover: #A60C0C;
  --msys-ds-danger-muted: #FFF2F2;

  /* WARNING */
  --msys-ds-warning-base: #FFB803;
  --msys-ds-warning-hover: #DEB124;
  --msys-ds-warning-muted: #FFFCF2;

  /* VIOLET */
  --msys-ds-violet-base: #9E28B2;
  --msys-ds-violet-hover: #5D1269;
  --msys-ds-violet-muted: #F8E8FB;

  /* INDIGO */
  --msys-ds-indigo-base: #663BB7;
  --msys-ds-indigo-hover: #43247D;
  --msys-ds-indigo-muted: #EDE3FF;

  /* OLIVE */
  --msys-ds-olive-base: #009688;
  --msys-ds-olive-hover: #016057;
  --msys-ds-olive-muted: #DCF9F6;

  /* ORANGE */
  --msys-ds-orange-base: #FD5722;
  --msys-ds-orange-hover: #CE471B;
  --msys-ds-orange-muted: #FFE4DB;

  /* ASH */
  --msys-ds-ash-base: #5F7D8C;
  --msys-ds-ash-hover: #395867;
  --msys-ds-ash-muted: #E8F7FF;

  /* BROWN */
  --msys-ds-brown-base: #795546;
  --msys-ds-brown-hover: #583E33;
  --msys-ds-brown-muted: #FFF1EB;

  /* GOLD */
  --msys-ds-gold-base: #F6E226;
  --msys-ds-gold-hover: #EAD200;
  --msys-ds-gold-muted: #FDF8CA;
}
